import hljs from "highlight.js/lib/index";

import("highlight.js/styles/" + process.env.VUE_APP_HIGHLITH_STYLE + ".css");

export default {
  // images processing
  replaceImageLinks(input) {
    if (typeof input === "string") {
      return input.replaceAll(
        "__apiLink__",
        process.env.VUE_APP_BACKEND_ROOT_URL
      );
    }
    console.error("ERROR: input is not a string.", input);
    return input;
  },

  replaceVideoFiles(input) {
    // todo this should be refactored, it works magically
    if (typeof input === "string") {
      return input
        .replaceAll("oembed", "iframe")
        .replaceAll("url", "src")
        .replaceAll("watch?v=", "embed/")
        .replaceAll("oembed", "iframe");
    }
    console.error("ERROR: input is not a string.", input);
    return input;
  },

  // spoilers
  showSpoilers() {
    // replace template for the spoilers
    const spoilers = document.getElementsByClassName("spoiler");
    if (spoilers) {
      this.updateSpoilers(spoilers);
    }
  },
  updateSpoilers(spoilers) {
    for (let i = 0; i < spoilers.length; i++) {
      const spoiler = spoilers.item(i);
      if (spoiler.getElementsByClassName("spoiler-content").item(0)) {
        spoiler.innerHTML =
          `<div class="card">
           <div class="card-header">
              <div data-toggle="collapse" href="#collapse-spoiler-` +
          i +
          `" role="button" aria-expanded="false" aria-controls="collapse-spoiler-` +
          i +
          `">
              ▼ ` +
          spoiler.getElementsByClassName("spoiler-title").item(0).textContent +
          `
              </div>
           </div>
           <div class="collapse" id="collapse-spoiler-` +
          i +
          `">
              <div class="card-body">
                 ` +
          spoiler.getElementsByClassName("spoiler-content").item(0).innerHTML +
          `
              </div>
           </div>
        </div>`;
      }
    }
  },
  updateLinks() {
    let modal = document.getElementById("modal-wrapper");
    if (modal) {
      const links = modal.getElementsByTagName("a");
      if (links && links.length > 0) {
        for (let i = 0; i < links.length; i++) {
          let link = links[i];
          if (!link.hasAttribute("target")) {
            link.setAttribute("target", "_blank");
          }
        }
      }
    }
  },

  initHighlighting() {
    hljs.highlightAll();
  }
};
