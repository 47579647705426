export default {
  state: () => ({
    showWelcomeModal: false
  }),
  mutations: {
    SET_SHOW_WELCOME_MODAL(state, showWelcomeModal) {
      state.showWelcomeModal = showWelcomeModal;
    }
  },
  actions: {
    SHOW_WELCOME_MODAL({ commit }, { isActive }) {
      return commit("SET_SHOW_WELCOME_MODAL", isActive);
    }
  },
  getters: {
    SHOW_WELCOME_MODAL: s => s.showWelcomeModal
  }
};
