<template>
  <main class="courses-home-wrapper">
    <HomeModal v-if="openModal || welcomeModalIsRequired" />
    <ModuleList />
    <Review />
    <ContactUs />
  </main>
</template>

<script>
import ModuleList from "@/components/ModuleList";
import Review from "@/components/Review";
import ContactUs from "@/components/ContactUs";
import HomeModal from "@/components/HomeModal";

export default {
  name: "Home",
  data() {
    return {
      openModal: false
    };
  },
  mounted() {
    if ("welcome" in this.$route.query) {
      this.openModal = true;
    }
  },
  computed: {
    welcomeModalIsRequired() {
      if (this.$store.getters.SHOW_WELCOME_MODAL) {
        this.$store.dispatch("SHOW_WELCOME_MODAL", { isActive: false });
        return true;
      }
      return false;
    }
  },
  components: { Review, ContactUs, ModuleList, HomeModal }
};
</script>

<style lang="scss">
@import "/public/template/scss/home.scss";
</style>
