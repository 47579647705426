<template>
  <div ref="search" class="search">
    <input
      type="text"
      class="search_searchTerm"
      :placeholder="placeholder"
      @input="onInput"
      @keyup.enter="handleSearch"
      @focusin="focusIn"
      @focusout="focusOut"
      v-model="searchPhrase"
    />
    <button
      @click.prevent="handleSearch"
      type="submit"
      class="search_searchButton"
    >
      <svg
        class="search-input-svg"
        fill="none"
        height="22"
        viewBox="0 0 22 22"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="9.5"
          cy="9.5"
          r="8.5"
          stroke="black"
          stroke-width="2"
        ></circle>
        <path d="M18 18L21 21" stroke="black" stroke-width="2"></path>
      </svg>
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SearchBar",
  props: ["placeholder"],
  data() {
    return {
      searchPhrase: "",
      result: ""
    };
  },
  methods: {
    handleSearch() {
      // if you want handle whole search input
      axios
        .get(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/search?searchRow==" +
            this.searchPhrase
        )
        .then(response => {
          this.result = response.data;
          this.$store.dispatch("SEARCH_RESULT", { result: this.result });
        })
        .then(() => {
          this.$router.push("/searchResult");
        });
    },
    focusIn() {
      this.$refs.search.classList.add("long-search");
    },
    focusOut() {
      this.$refs.search.classList.remove("long-search");
    }
  }
};
</script>

<style lang="scss" scoped>
.long-search {
  position: absolute;
  .search_searchTerm {
    width: 300px;
  }
}

.search {
  position: absolute;
  right: 260px;
  display: flex;
  height: 40px;
  margin: 18px;
  &_searchTerm {
    width: 60px;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    background: #fff;
    border: 2px solid #d1d5db;
    border-right: none;
    border-radius: 6px 0 0 6px;
    padding: 6px 10px;
    height: inherit;
    outline: none;
    &::placeholder {
      color: #fff;
    }
  }
  &_searchButton {
    height: inherit;
    background: transparent;
    color: #fff;
    border: 2px solid #d1d5db;
    border-radius: 0 6px 6px 0;
    border-left: none;
    cursor: pointer;
    font-size: 20px;
    padding: 6px 10px 6px 0;
    svg {
      width: 20px;
      height: 20px;
      margin-top: -10px;
    }
  }
}
</style>
