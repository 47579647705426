import axios from "axios";

export default {
  state: {
    steps: []
  },
  mutations: {
    SET_STEPS(state, steps) {
      state.steps = steps;
    }
  },
  actions: {
    LOAD_STEPS({ commit }, { taskId }) {
      if (!taskId) {
        return commit("SET_STEPS", []);
      }

      const req1 = axios.get(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/task-steps?search=task.id==" +
          taskId
      );
      const req2 = axios.get(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/user-task-steps?search=userTask.task.id==" +
          taskId
      );

      let steps = [];
      return axios
        .all([req1, req2])
        .then(
          axios.spread((...responses) => {
            let taskSteps = responses[0].data;
            let userSteps = responses[1].data;

            const map = new Map();
            taskSteps.forEach(item => map.set(item.id, item));
            userSteps.forEach(item =>
              map.set(item.stepId, {
                ...map.get(item.stepId),
                ...item
              })
            );

            steps = Array.from(map.values()).sort(
              (f, s) => f.priority - s.priority
            );
            steps.forEach(step => {
              step.completed =
                step.status === "COMPLETED" || step.status === "SKIPPED";
            });
          })
        )
        .then(() => {
          commit("SET_STEPS", steps);
        });
    }
  },
  getters: {
    STEPS: state => state.steps
  }
};
