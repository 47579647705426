<template>
  <header class="header">
    <div class="header-wrapper center">
      <div class="logo header-logo">
        <router-link to="/">
          <svg
            height="52"
            viewBox="0 0 25 52"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.986 23.0839C12.7251 22.7047 12.3218 22.5151 11.8236 22.5151H11.3254C10.9459 22.5151 10.6375 22.8232 10.6375 23.2024L10.6138 26.2835C10.6138 26.6627 10.9222 26.9945 11.3017 26.9945H11.8711C12.4167 26.9945 12.82 26.8049 13.0809 26.4257C13.3419 26.0465 13.4605 25.5013 13.4605 24.7666C13.413 24.0319 13.247 23.4868 12.986 23.0839Z"
            />
            <path
              d="M8.95312 51.4294H16.3546C16.236 51.3109 16.1648 51.145 16.1648 50.9791L16.0225 47.7085L15.9988 41.8782L15.9039 41.8308L14.7889 46.0494L14.267 47.7321C14.1721 48.0166 13.9112 48.2299 13.6028 48.2299H11.705C11.3966 48.2299 11.1356 48.0403 11.0407 47.7559L10.4951 46.0731L9.33269 41.8545L9.2378 41.9019L9.26152 47.7321L9.14291 50.9554C9.14291 51.145 9.07174 51.3109 8.95312 51.4294Z"
            />
            <path
              d="M24.99 49.1778L20.625 1.87231C20.5301 0.805805 19.6287 0 18.5611 0H14.0538L13.8878 2.77292C13.8641 3.12842 13.5557 3.43652 13.1998 3.43652H12.1323C11.7527 3.43652 11.4443 3.12842 11.4443 2.74922V0.0237001H6.43886C5.37134 0.0237001 4.4936 0.829505 4.37499 1.89601L0.0100229 49.1778C-0.10859 50.3865 0.840316 51.4293 2.07389 51.4293H6.39142C6.24908 51.2871 6.17791 51.0975 6.20163 50.9079L7.07937 36.2612C7.1031 35.9057 7.41149 35.6213 7.76733 35.6213H9.21441C9.52281 35.6213 9.78376 35.8109 9.87865 36.0953L11.6816 41.6885C12.156 43.0869 12.4644 44.0349 12.583 44.4852H12.6542C12.7728 44.0349 13.0575 43.0869 13.5319 41.6885L15.2874 36.0953C15.3823 35.8109 15.6432 35.6213 15.9516 35.6213H17.3987C17.7546 35.6213 18.063 35.9057 18.0867 36.2612L19.1068 50.9079C19.1305 51.1212 19.0356 51.3108 18.917 51.453H22.9261C24.1597 51.4293 25.1086 50.3865 24.99 49.1778ZM16.8531 35.4554H14.7655C14.5046 35.4554 14.2673 35.3132 14.1487 35.0762L11.8476 30.4547C11.729 30.2177 11.4918 30.0755 11.2308 30.0755C10.8513 30.0755 10.5429 30.3836 10.5429 30.7628L10.4954 34.7681C10.4954 35.1473 10.187 35.4554 9.80748 35.4554H7.8385C7.43521 35.4554 7.12682 35.1236 7.15054 34.7207L7.88594 20.074C7.90967 19.7659 8.12317 19.5289 8.38412 19.4341C8.12317 19.3156 7.93339 19.0786 7.95711 18.7705L8.02828 17.1589C8.052 16.8271 8.31295 16.5427 8.64507 16.4953C9.28558 16.4242 9.78376 16.2109 10.1396 15.8791C10.5903 15.4525 10.8038 14.6704 10.8276 13.5565L11.3495 6.06724C11.3732 5.71173 11.6816 5.42733 12.0374 5.42733H13.1286C13.5082 5.42733 13.8166 5.73543 13.8166 6.11464L13.9352 13.5802C14.0064 15.4288 13.5557 16.8745 12.5593 17.941C11.8002 18.7231 10.7327 19.1971 9.42792 19.4104H11.8476C13.1761 19.4104 14.2436 19.8844 15.0502 20.8087C15.8805 21.7567 16.3549 23.0602 16.4735 24.7429C16.5447 25.8332 16.4261 26.7575 16.1414 27.5396C15.9042 28.1558 15.5484 28.6535 15.0976 29.0327C14.8604 29.246 14.813 29.6015 14.9553 29.8859L17.4936 34.3889C17.7308 34.8866 17.3987 35.4554 16.8531 35.4554Z"
            />
          </svg>
          <span
            >Java<br />
            Road<br />
            Map</span
          >
        </router-link>
      </div>
    </div>
  </header>

  <router-view />
</template>

<script>
export default {
  name: "EmptyLayout"
};
</script>

<style lang="scss">
@import "/public/template/scss/header.scss";
</style>
