<template>
  <section class="courses-reviews">
    <div class="courses-reviews-wrapper">
      <swiper
        :pagination="{ clickable: true }"
        :slides-per-view="2"
        @slideChange="onSlideChange"
        @swiper="onSwiper"
      >
        <swiper-slide v-for="(review, index) in reviews" :key="index">
          <div class="swiper-image-wrapper">
            <img :src="`${review.profileImage}`" alt="" />
          </div>
          <div class="swiper-info-wrapper">
            <p class="swiper-info-title">{{ review.name }}</p>
            <p class="swiper-info-text">{{ review.review }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";

export default {
  name: "Review",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      reviews: []
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/reviews")
      .then(response => (this.reviews = response.data));
  },
  methods: {
    onSwiper(/** swiper **/) {},
    onSlideChange() {}
  }
};
</script>

<style lang="scss">
@import "swiper/swiper.scss";
@import "swiper/components/pagination/pagination.scss";
@import "/public/template/scss/review.scss";
</style>
