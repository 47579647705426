import axios from "axios";
import { UtilsService } from "@/services";

function listToTree(list) {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentTaskId) {
      list[map[node.parentTaskId]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
}

export default {
  state: {
    tasks: [],
    task: {},
    commonTaskRating: {}
  },
  mutations: {
    SET_TASKS(state, tasks) {
      state.tasks = tasks;
    },
    SET_TASK(state, task) {
      state.task = task;
    },
    SET_COMMON_RATING(state, commonTaskRating) {
      state.commonTaskRating = commonTaskRating;
    }
  },
  actions: {
    LOAD_TASK({ commit }, { task }) {
      return axios
        .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/tasks/" + task.id)
        .then(res => res.data)
        .then(loadedTask => Object.assign(task, loadedTask))
        .then(() => {
          task.article = UtilsService.replaceImageLinks(task.article);
          task.article = UtilsService.replaceVideoFiles(task.article);
          task.completed =
            task.status === "COMPLETED" || task.status === "SKIPPED";
          return task;
        })
        .then(task => {
          commit("SET_TASK", task);
        });
    },
    LOAD_COMMON_RATING({ commit }, { task }) {
      return axios
        .get(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/user-tasks/" +
            task.generatedTaskId +
            "/common-rating/"
        )
        .then(response => {
          commit("SET_COMMON_RATING", response.data);
        });
    },
    LOAD_TASKS({ commit }, { moduleId }) {
      const req1 = axios.get(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/tasks?search=module.code==" +
          moduleId
      );
      const req2 = axios.get(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/user-tasks?search=task.module.code==" +
          moduleId
      );

      let tasks = [];
      return axios
        .all([req1, req2])
        .then(
          axios.spread((...responses) => {
            const loadedTasks = responses[0].data;
            const userTasks = responses[1].data;
            if (!loadedTasks.length || !userTasks.length) {
              return;
            }

            const map = new Map();
            userTasks.forEach(item => map.set(item.taskId, item));
            loadedTasks.forEach(item => {
              if (!map.get(item.id)) {
                item.status = "BLOCKED";
              }
              map.set(item.id, {
                ...map.get(item.id),
                ...item
              });
            });

            tasks = listToTree(Array.from(map.values()));
            const flatten = data =>
              data.flatMap(it => [it, ...flatten(it.children, it.id)]);
            tasks = flatten(tasks);
            tasks[tasks.length - 1].final = true;
          })
        )
        .then(() => {
          commit("SET_TASKS", tasks);
        });
    },
    UPDATE_USER_TASK_STATE({ commit }, { task }) {
      return axios
        .get(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/user-tasks/" +
            task.generatedTaskId
        )
        .then(res => res.data)
        .then(loadedTask => Object.assign(task, loadedTask))
        .then(task => {
          task.completed =
            task.status === "COMPLETED" || task.status === "SKIPPED";
          return commit("SET_TASK", task);
        });
    },
    UPDATE_RATING({ commit }, { task, rating }) {
      task.rating = rating;
      return commit("SET_TASK", task);
    },
    SET_TASK({ commit }, { task }) {
      return commit("SET_TASK", task);
    },

    SET_COMMON_RATING({ commit }, { commonTaskRating }) {
      return commit("SET_COMMON_RATING", commonTaskRating);
    }
  },
  getters: {
    TASKS: state => state.tasks,
    TASK: state => state.task,
    COMMON_RATING: state => state.commonTaskRating
  }
};
