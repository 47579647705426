import axios from "axios";

export default {
  state: () => ({
    newNotifications: [],
    notifications: []
  }),
  mutations: {
    SET_NEW_NOTIFICATIONS(state, newNotifications) {
      state.newNotifications = newNotifications;
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
      state.notifications.sort((a, b) => {
        if (a.createdOn < b.createdOn) return 1;
        if (a.createdOn > b.createdOn) return -1;
        return state.notifications;
      });
    },
    CLEAR_NEW_NOTIFICATIONS(state) {
      state.newNotifications = [];
    }
  },
  actions: {
    LOAD_NOTIFICATIONS({ commit, dispatch }) {
      return axios
        .get(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/notifications/web/all-notifications"
        )
        .then(response => {
          commit("SET_NOTIFICATIONS", response.data);
          commit("CLEAR_NEW_NOTIFICATIONS");
          dispatch("READ_NOTIFICATIONS");
          return response;
        });
    },
    LOAD_NEW_NOTIFICATIONS({ commit }) {
      return axios
        .get(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/notifications/web/new-notifications/3"
        )
        .then(response => {
          commit("SET_NEW_NOTIFICATIONS", response.data);
          return response;
        });
    },
    READ_NOTIFICATIONS() {
      return axios.post(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/notifications/web/read-notifications"
      );
    }
  },
  getters: {
    NEW_NOTIFICATIONS: s => s.newNotifications,
    NOTIFICATIONS: s => s.notifications
  }
};
