import { createApp } from "vue";
import App from "./App.vue";
import { useVuelidate } from "@vuelidate/core";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import SwiperCore, { Pagination } from "swiper";
import Clipboard from "v-clipboard3";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "toastr/build/toastr.min.css";
import hljs from "highlight.js/lib/highlight";

SwiperCore.use([Pagination]);

axios.defaults.withCredentials = false;
axios.interceptors.request.use(async function(config) {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? "Bearer " + token : "";
  config.timeout = process.env.VUE_APP_REQUEST_TIMEOUT;
  return config;
});

createApp(App)
  .use(store)
  .use(useVuelidate)
  .use(router)
  .use(Clipboard)
  .use(hljs)
  .mount("#app");
