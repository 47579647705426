<template>
  <div :class="{ open: open }" class="modal">
    <div class="modal-wrapper">
      <form action="" class="modal-form-wrapper">
        <div class="modal-form-text-part">
          <i class="material-icons modal-close" @click="closeModal()">close</i>
          <p class="modal-title">Для кого наша платформа?</p>
          <div class="greeting-modal-page">
            <img
              style="float: right"
              src="/template/img/icons/logo.png"
              alt="Java Road Map Welcome Page"
            />
            <h3>Привет!</h3>
            <p>
              Позволь я вкратце расскажу, что тебя ждёт на нашей платформе:
            </p>
            <ul>
              <li>
                Прежде всего мы стараемся делать упор на качество и
                последовательность нашего контента, который состоит из:
              </li>
            </ul>
            <ul class="list-group">
              <li class="list-group-item">Текстового материала</li>
              <li class="list-group-item">Видеороликов</li>
              <li class="list-group-item">
                отсылок на различные полезные источники, которые прошли через
                наше ревью.
              </li>
              <li class="list-group-item">
                мини-заданий, которые вы выполняете после уроков из
                практического модуля.
              </li>
              <li class="list-group-item">
                комплексных задач, которые ждут вас в конце каждого модуля для
                закрепления всего раздела.
              </li>
              <li class="list-group-item">
                Возможно реальное общение со мной, возможно
                для получения каких-либо консультаций или других вопросов.
              </li>
            </ul>

            <hr />

            <ul>
              <li>
                Вы можете вступить в телеграмм группу, где сможете общаться не только с
                уже зарегистрированными пользователями системы, но и с нами,
                разработчиками учебника. Мы, в свою очередь, постараемся давать
                подробные ответы на интересующие вас вопросы. Своего рода
                менторинг от разработчиков, которые не один год в профессии.
              </li>

              <hr />

              <hr />

              <li>
                Неотъемлемой частью интерактива является разработанный нами
                плагин к IDE Intellij Idea и тут снова есть несколько.
                преимуществ:
              </li>

              <br />

              <div class="list-group">
                <p class="list-group-item list-group-item-primary">
                  Вы набиваете руку программируя в системе, которую использует
                  90% разработчиков.
                </p>
                <p class="list-group-item list-group-item-success">
                  Вам не придется долго ждать результатов выполнения задач —
                  проверка занимает от 5 до 10 секунд.
                </p>
                <p class="list-group-item list-group-item-info">
                  Мини задания разбиты на шаги, каждый шаг отдельно будет
                  проверен нашей системой и если вдруг вы неправильно выполните
                  задание, система покажет, где именно вы ошиблись и выдвинет
                  своё предположение в чем заключается ошибка.
                </p>
              </div>
            </ul>

            <div class="info-hr"></div>

            <p class="info">
              Итак, предлагаю познакомиться и ознакомиться с модулем, который
              поможет вам понять как пользоваться платфрмой.
              <a href="https://javaroadmap.ru/module/platform">про платформу</a
              >.
            </p>
          </div>

          <div class="info-hr"></div>

          <div class="modal-form-btn-wrapper">
            <button
              class="modal-form-btn submit"
              type="reset"
              @click="closeModal()"
            >
              Начать!
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeModal",
  data() {
    return {
      open: false
    };
  },
  mounted() {
    this.showModal();
  },
  methods: {
    showModal() {
      document.body.classList.add("body-active");
      this.open = true;
    },
    closeModal() {
      document.body.classList.remove("body-active");
      this.open = false;
      this.$router.push("/");
    }
  }
};
</script>

<!-- TODO Refactoring Each Component with individual style sheet. -->
<style lang="scss" scoped>
@import "/public/template/scss/modal.scss";

.body-active .modal-wrapper {
  animation: 2s ease-in-out 1 alternate top-right-movement;
}

@keyframes top-right-movement {
  from {
    transform: translateY(-100%);
    right: 100%;
  }
  to {
    right: 0;
    transform: translateY(0);
  }
}

.info {
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  padding: 22px 60px;
  font-family: "PT Mono", sans-serif;
  border-radius: 18px;
  color: #000;
  background-color: #ffcf77;
  border: none;
  transition: 0.2s ease;
}

.info-hr {
  width: 100%;
  background: #175663;
  margin-bottom: 20px;
  padding: 10px 67px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
