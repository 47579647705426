<template>
  <section class="modules">
    <div class="modules-background">
      <img alt="" src="/template/img/modules-bg.svg" />
    </div>

    <div class="modules-content-wrapper">
      <div class="modules-header">
        <div class="modules-user-image-wrapper">
          <img alt="" src="/template/img/modules-user.svg" />
        </div>
        <div class="modules-information-wrapper">
          <p class="modules-title">
            Все модули
          </p>
          <p class="modules-info">
            Прокачай скиллы вместе с нами!
          </p>
        </div>
      </div>

      <div class="modules-body">
        <div class="modules-flag-wrapper">
          <div
            v-for="(flag, index) in flags"
            :class="`modules-flag-text${index}`"
            :key="flag"
            v-html="flag"
          ></div>
        </div>
        <div class="modules-item-wrapper">
          <div
            v-for="(module, index) in modules"
            :class="
              `modules-item${index + 1} ${
                module.available ? '' : 'deactivated'
              }`
            "
            :key="module"
          >
            <span
              :class="
                `${
                  module.available && module.completed ? 'completed' : 'd-none'
                }`
              "
            >
              <svg
                fill="none"
                height="19"
                viewBox="0 0 24 19"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 8.13889L8.90909 15L22 2"
                  stroke="white"
                  stroke-width="5"
                ></path>
              </svg>
            </span>
            <router-link
              class="modules-link"
              :to="`/module/${module.code}`"
              v-html="module.name"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

const mergeByKey = (a1, a2) =>
  a1.map(first => ({
    ...a2.find(second => first.code === second.code && second),
    ...first
  }));

export default {
  data() {
    return {
      flags: [
        `Сундук<br>знаний`,
        `ЯП и их <br>особенности`,
        ``,
        `Фреймворки`,
        `SQL`
      ],
      modules: [
        { code: "platform" },
        { code: "algorithmic" },
        { code: "instruments" },
        { code: "java-core" },
        { code: "java-advanced" },
        { code: "database-basic" },
        { code: "spring-core" },
        { code: "spring-web" },
        { code: "spring-database" }
      ]
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/modules")
      .then(response => {
        this.modules = mergeByKey(this.modules, response.data);
      });
    axios
      .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/user-modules/completed")
      .then(response => {
        this.modules = mergeByKey(this.modules, response.data);
      });
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/module-list.scss";
</style>
