import axios from "axios";
import { NotificationService } from "@/services";

export default {
  state: () => ({
    user: {},
    subscription: {}
  }),
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_SUBSCRIPTION(state, subscription) {
      state.subscription = subscription;
    },
    CLEAR_USER(state) {
      state.user = {};
    }
  },
  actions: {
    FETCH_USER({ dispatch, commit }) {
      return axios
        .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/users/me")
        .then(res => {
          let user = res.data;
          user.isGuest = user.status === "UNREGISTERED";
          if (user.isGuest) {
            user.username = "Гость";
          }
          return user;
        })
        .then(user => commit("SET_USER", user))
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 404) {
            localStorage.removeItem("accessToken");
            return axios
              .post(process.env.VUE_APP_GUEST_ENDPOINT_URL, {})
              .then(res => {
                const token = res.data.accessToken;
                localStorage.setItem("accessToken", token);
                dispatch("SHOW_WELCOME_MODAL", {
                  isActive: res.data.welcome
                });
              })
              .then(() => dispatch("FETCH_USER"));
          }
        });
    },
    UPDATE_SETTINGS({ dispatch }, { username, name, email, password }) {
      return axios
        .post(process.env.VUE_APP_BACKEND_ROOT_URL + "/users/settings", {
          username: username,
          name: name,
          email: email,
          password: password
        })
        .then(res => {
          NotificationService.success("Данные успешно обновлены");
          dispatch("FETCH_USER");
          return res;
        })
        .catch(error => {
          if (error.response.status === 403) {
            NotificationService.error(
              "Для изменения данных профиля пройдите email верификацию"
            );
          }
          throw error;
        })
        .catch(error => {
          if (error.response.status === 404 || error.response.status === 500) {
            NotificationService.error(
              "Неверные данные формы или произошла ошибка. Обратитесь к администрации."
            );
          }
          throw error;
        })
        .catch(error => {
          if (error.response.status === 409) {
            console.log(error.response.data.message);
            if (error.response.data.message === "login already exist") {
              NotificationService.error(
                "Пользователь с таким логином уже существует."
              );
              throw error;
            }
            if (error.response.data.message === "email already exist") {
              NotificationService.error(
                "Пользователь с таким email уже существует."
              );
              throw error;
            }
          }
        });
    },
    LOAD_SUBSCRIPTION({ commit }) {
      return axios
        .get(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/user-subscriptions/fetchCurrentSubscription"
        )
        .then(res => commit("SET_SUBSCRIPTION", res.data));
    }
  },
  getters: {
    USER: s => s.user,
    SUBSCRIPTION: s => s.subscription
  }
};
