<template>
  <section class="contact-us" id="linkToContactUs">
    <div class="contact-us-wrapper">
      <p class="contact-us-title">Связаться с нами</p>
      <p class="contact-us-info">
        Не стесняйся задавать вопросы по поводу заданий!
      </p>
      <div class="contact-us-form-images-wrapper">
        <div class="contact-us-form-wrapper">
          <div class="contact-us-input-wrapper">
            <input
              v-model="name"
              class="contact-us-input-field name-input :required"
              name="name"
              placeholder="Твое имя"
              title="name"
              type="text"
            />
          </div>
          <div class="contact-us-input-wrapper">
            <input
              v-model="contact"
              class="contact-us-input-field email-input :required"
              name="contact"
              placeholder="Email или телега"
              title="contact"
              type="text"
            />
          </div>
          <div class="contact-us-input-img-wrapper">
            <textarea
              v-model="review"
              class="question-textarea"
              placeholder="Суть вопроса"
            />
          </div>
          <div class="contact-us-btn-wrapper">
            <button class="button" @click="sendFeedback()">Отправить!</button>
          </div>
        </div>
        <div class="contact-us-images-wrapper">
          <div class="contact-us-image">
            <img alt="" src="/template/img/contact-group.svg" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { NotificationService } from "@/services";
import axios from "axios";

export default {
  data: function() {
    return {
      name: "",
      contact: "",
      review: ""
    };
  },
  methods: {
    sendFeedback() {
      axios
        .post(process.env.VUE_APP_BACKEND_ROOT_URL + "/feedback", {
          name: this.name,
          contact: this.contact,
          review: this.review
        })
        .then(() => NotificationService.success("Вопрос успешно отправлен!"));
      this.name = "";
      this.contact = "";
      this.review = "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/contact-us.scss";
</style>
