import { createStore } from "vuex";
import authModule from "./auth";
import infoModule from "./info";
import taskModule from "./task";
import stepModule from "./step";
import notificationModule from "./notification";
import commonModule from "./common";
import searchModule from "./search";

const store = createStore({
  modules: {
    auth: authModule,
    info: infoModule,
    task: taskModule,
    step: stepModule,
    notification: notificationModule,
    common: commonModule,
    search: searchModule
  }
});

export default store;
