import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import OAuth2RedirectHandlerView from "@/views/OAuth2RedirectHandlerView";
import store from "../store";

const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: "/oauth2/redirect",
    component: OAuth2RedirectHandlerView
  },
  {
    path: "/settings",
    component: () => import("../views/Settings"),
    async beforeEnter(to, from, next) {
      let guest = store.getters.USER.isGuest;
      if (guest === undefined) {
        await store.dispatch("FETCH_USER");
        guest = store.getters.USER.isGuest;
      }
      if (!guest) {
        next();
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/login",
    meta: { layout: "empty" },
    component: () => import("../views/Login")
  },
  {
    path: "/register",
    meta: { layout: "empty" },
    component: () => import("../views/Register")
  },

  {
    path: "/forgotPassword",
    meta: { layout: "empty" },
    component: () => import("../views/ForgotPassword")
  },
  {
    path: "/notifications",
    component: () => import("../views/Notifications")
  },
  {
    path: "/searchResult",
    component: () => import("../views/SearchResult")
  },
  {
    path: "/tariffs",
    component: () => import("../views/Tariffs")
  },
  {
    path: "/module/:code/:taskLink",
    component: () => import("../views/Module")
  },
  {
    path: "/module/:code",
    component: () => import("../views/Module"),
    props: true
  },
  {
    path: "/offer",
    component: () => import("../views/Offer")
  },
  {
    path: "/contact",
    component: () => import("../views/Contact")
  },
  {
    path: "/verify/email",
    component: () => import("../views/Verify")
  },
  {
    path: "/return_url/:paymentId",
    component: () => import("../views/PaymentConfirmation")
  },
  {
    path: "/blogs",
    component: () => import("../views/Blog")
  },
  {
    path: "/blogs/:blogId",
    component: () => import("../views/BlogPage")
  },
  // links to subscription descriptions
  {
    path: "/platform",
    meta: { layout: "empty" },
    component: () => import("../views/TariffsPlatform.vue")
  },
  {
    path: "/personal",
    meta: { layout: "empty" },
    component: () => import("../views/TariffsPersonal.vue")
  },
  {
    path: "/junior",
    meta: { layout: "empty" },
    component: () => import("../views/TariffsJunior")
  },
  {
    path: "/telegram",
    meta: { layout: "empty" },
    component: () => import("../views/TelegramSubscription")
  },
  {
    path: "/individual",
    meta: { layout: "empty" },
    component: () => import("../views/TariffsPersonal")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
