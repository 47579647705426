import toastr from "toastr";

export default {
  error(message, options) {
    this.printNotification(message, "error", options);
  },
  success(message, options) {
    this.printNotification(message, "success", options);
  },
  warning(message, options) {
    this.printNotification(message, "warning", options);
  },
  info(message, options) {
    this.printNotification(message, "info", options);
  },
  printNotification(message, type, inOptions) {
    const options = {
      progressBar: true,
      positionClass: "toast-bottom-right",
      showDuration:
        inOptions && inOptions.showDuration ? inOptions.showDuration : "300",
      hideDuration: "500",
      timeOut: inOptions && inOptions.timeOut ? inOptions.timeOut : "3000",
      extendedTimeOut: "1000"
    };

    toastr[type](message, null, options);
  }
};
