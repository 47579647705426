<template>
  <div class="notifications">
    <div class="notifications-status">
      <a
        href="/notifications"
        class="icon"
        @click="toggleMobileNotifications($event)"
      >
        <span class="count" v-bind:style="hideNotifications">
          {{ notifications ? notifications.length : "" }}</span
        >
        <svg
          height="22"
          viewBox="0 0 19 22"
          width="19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="m7.01318 18.7019c0 1.2885 1.04668 2.3378 2.33778 2.3378 1.28844 0 2.33774-1.0467 2.33774-2.3378z"
            />
            <path
              d="m18.3105 14.4089-1.9406-2.8447v-4.59563c0-3.8179-3.0523-6.903956-6.85598-6.96647264-.02707-.00043833-.05413-.00071228-.08131-.00087666-.02712.00049312-.05424.00109583-.08131.0018629-3.80327.1101304-6.93661 3.2347064-7.03321 7.0549564l-.11309 4.47466-1.87495 2.8497c-.709928 1.079-.2365879 1.9536 1.05539 1.9536h15.89896c1.2928.0001 1.7513-.8641 1.0261-1.9271zm-14.92941-.4106 1.14399-1.7387.12996-5.14244c.06537-2.58488 2.21373-4.71806 4.79214-4.77795 2.54392.02729 4.58492 2.08064 4.58492 4.62941v5.31708l1.1683 1.7127h-11.81931z"
            />
            <path
              d="m9.35132.00104103c.02712-.00016437.05424-.00016437.08131-.00005479.02706-.00049312.05413-.00082187.08125-.00098624z"
            />
          </g>
        </svg>
      </a>
    </div>
    <div class="notifications-list" v-bind:style="hideNotifications">
      <div class="list">
        <div
          class="item"
          :key="notification.id"
          v-for="notification in notifications"
        >
          <p v-html="notification.message"></p>
        </div>
      </div>
      <router-link
        class="button"
        to="/notifications"
        @click="closeNotifications()"
        >Смотреть все
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuNotification",
  props: {
    mobileNotificationsOpen: null
  },
  data: () => {
    return {
      pooling: null
    };
  },
  mounted() {
    this.$store.dispatch("LOAD_NEW_NOTIFICATIONS");
    this.polling = setInterval(() => {
      this.$store.dispatch("LOAD_NEW_NOTIFICATIONS");
    }, 10000);
  },
  unmounted() {
    clearInterval(this.polling);
  },
  computed: {
    hideNotifications() {
      return {
        visibility:
          this.notifications && this.notifications.length ? "" : "hidden"
      };
    },
    notifications() {
      return this.$store.getters.NEW_NOTIFICATIONS;
    }
  },
  methods: {
    isMobile() {
      return screen.width <= 760;
    },
    toggleMobileNotifications(event) {
      if (this.notifications.length > 0) {
        if (this.isMobile()) event.preventDefault();
        this.$emit("toggle-mobile-notifications");
      }
    },
    closeNotifications() {
      this.$emit("close-mobile-notifications");
    }
  }
};
</script>
