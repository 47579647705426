<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  components: { MainLayout, EmptyLayout },
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    }
  }
};
</script>

<style lang="scss">
@import "/public/template/scss/common.scss";
@import "/public/template/fonts/stylesheet.scss";
</style>
