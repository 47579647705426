<script>
import { NotificationService } from "@/services";

export default {
  mounted() {
    const token = this.obtainUrlParameter("token");

    if (token !== undefined) {
      localStorage.setItem("accessToken", token);
      this.$store.dispatch("FETCH_USER");
      NotificationService.success("Successfully logged in");
    } else {
      NotificationService.error("An error occurred while trying to signin");
    }

    this.$router.push("/");
  },
  methods: {
    obtainUrlParameter() {
      const url = window.location.search;
      return url.includes("?token=") === false
        ? undefined
        : url.replace("?token=", "");
    }
  }
};
</script>
