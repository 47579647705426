import axios from "axios";
import { NotificationService } from "@/services";

export default {
  actions: {
    login({ dispatch }, { username, password }) {
      return axios
        .post(
          process.env.VUE_APP_SIGNIN_ENDPOINT_URL,
          { username: username, password: password },
          {}
        )
        .then(res => {
          localStorage.setItem("accessToken", res.data.accessToken);
          NotificationService.success("Успешный вход");
          return res;
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            NotificationService.error(
              "Неверные данные формы или пользователь не найден. Попробуйте ещё раз."
            );
            throw error;
          }
          NotificationService.error(
            "Произошла ошибка авторизации. Обратитесь к администрации."
          );
          throw error;
        });
    },

    /*eslint no-unused-vars: "off"*/
    register({ commit }, { username, name, email, password }) {
      return axios
        .post(process.env.VUE_APP_SIGNUP_ENDPOINT_URL, {
          username: username,
          name: name,
          email: email,
          password: password
        })
        .then(res => {
          NotificationService.success("Успешно зарегистрирован");
          return res;
        })
        .catch(error => {
          if (error.response.status === 409) {
            if (error.response.data.message === "userName already exist") {
              NotificationService.error(
                "Пользователь с таким логином уже существует."
              );
              throw error;
            }
            if (error.response.data.message === "email already exist") {
              NotificationService.error(
                "Пользователь с таким email уже существует."
              );
              throw error;
            }
          }
          NotificationService.error(
            "Произошла ошибка авторизации. Обратитесь к администрации."
          );
          throw error;
        });
    },

    logout({ commit, dispatch }) {
      return axios.get(process.env.VUE_APP_LOGOUT_ENDPOINT_URL).then(res => {
        localStorage.removeItem("accessToken");
        NotificationService.success("Успешный выход");
        commit("CLEAR_USER");
        dispatch("FETCH_USER");
        return res;
      });
    }
  }
};
