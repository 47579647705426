export default {
  state: () => ({
    searchResult: []
  }),
  mutations: {
    SET_SEARCH_RESULT(state, searchResult) {
      state.searchResult = searchResult;
    }
  },
  actions: {
    SEARCH_RESULT({ commit }, { result }) {
      return commit("SET_SEARCH_RESULT", result);
    }
  },

  getters: {
    GET_SEARCH_RESULT: s => s.searchResult
  }
};
